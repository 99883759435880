<template>
  <v-dialog width="1000" v-model="dialog" persistent>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        elevation="0"
        @click="compose"
        style="height: 30px; width: 100%"
        >{{ $t("edit", { name: "Políticas" }) }}</v-btn
      >
    </template>
    <v-card
      :style="$vuetify.breakpoint.xsOnly ? 'height:auto' : 'height: 560px'"
    >
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        {{ $t("policys.title") }}
      </v-card-title>
      <div class="close">
        <v-btn
          icon
          @click="
            dialog = false;
            editor = false;
          "
        >
          <v-icon id="iconClose">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text v-if="editor">
        <editor
          :api-key="apiKey"
          height="400"
          v-if="editor"
          v-model="content"
          :init="init"
        />
      </v-card-text>
      <v-card-text v-else
        ><v-row
          class="mx-0"
          style="width: 100%; height: 460px; display: flex; align-items: center"
          justify="center"
        >
          <v-col align="center" cols="8" sm="4" md="2">
            <Loader></Loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="editor">
        <v-spacer />
        <v-btn
          outlined
          @click="
            dialog = false;
            editor = false;
          "
          class="mr-3"
          style="height: 30px; width: 100px"
          elevation="0"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          @click="
            $emit('validate', content);
            dialog = false;
            editor = false;
          "
          elevation="0"
          style="height: 30px; width: 100px !important"
          ><v-icon small style="margin-right: 5px">$save</v-icon
          >{{ $t("save", { name: "" }) }}</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "EditorPoliticaPrivacidad",
  components: {
    Editor: () => import("@tinymce/tinymce-vue"),
    Loader: () => import("@/components/ui/icons/Loader"),
  },
  mounted() {
    //this.compose();
  },
  computed: {
    apiKey() {
      return process.env.VUE_TINYMCE_API_KEY;
    },
  },
  data() {
    return {
      editor: false,
      content: "",
      dialog: false,
      init: {
        height: 400,
        content_style: "* {color: white}",
        menubar: false,
        selector: "#instance1",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | fontsizeselect formatselect|bold italic underline | forecolor backcolor |" +
          "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
      },
    };
  },
  methods: {
    compose() {
      setTimeout(() => {
        this.content =
          this.$store.getters["auth/getSetting"]("page_policy_appointment") !==
          null
            ? this.$store.getters["auth/getSetting"]("page_policy_appointment")
            : "";
        console.log(this.content);
        this.editor = true;
        console.log(this.editor);
      }, 1000);
    },
  },
};
</script>
<style lang="sass">
.tox .tox-notification.tox-notification--in.tox-notification--warning
  display: none
  scrollbar-color: yellow !important
  scrollbar-width: 10px !important

.tox .tox-edit-area
  border: red 1px solid
  border-color: var(--v-primary-base) !important
  position: absolute !important
  border-radius: 19px
  left: 0px
  right: 0px
  scrollbar-color: yellow !important
  scrollbar-width: 10px !important
  bottom: 40px
  top: 0
.tox-edit-area__iframe, .tox-toolbar, .tox-statusbar, .tox-toolbar__primary, .tox-toolbar__overflow
  background-color: var(--v-gris3-base) !important

.tox-tbtn
  border: solid 1px red

.tox-tbtn svg, .tox-tbtn__select-label, .tox-statusbar__text-container *
  fill: var(--v-primary-base) !important
  color: var(--v-primary-base) !important

#tinymce

  overflow: hidden
.tox-tinymce
  border-color: transparent !important
.tox *
  border-color: var(--v-primary-base) !important
.tox-toolbar__primary
  background: var(--v-gris3-base) !important
  border-bottom: 1px solid var(--v-primary-base) !important
  scrollbar-color: yellow !important
  scrollbar-width: 10px !important
.tox-tbtn--enabled, .tox-tbtn:hover
  background-color: var(--v-gris2-base) !important

.tox .tox-editor-header

  position: absolute
  left: 0
  right: 0
  bottom: -1px
@media (max-width: 780px)
  .tox .tox-edit-area
    border: red 1px solid
    border-color: var(--v-primary-base) !important
    position: absolute !important
    border-radius: 19px
    left: 0px
    right: 0px
    scrollbar-color: yellow !important
    scrollbar-width: 10px !important
    bottom: 50px
    top: 0
</style>
